const SELECTOR = 'label[for]:not([data-skip])'

class LabelNoScroll {
  static perform(e, element) {
    const target = window[element.htmlFor]
    if (target && target.type) {
      const type = target.type.toLowerCase()
      if (type === 'checkbox') {
        target.checked = !target.checked
      } else if (type === 'radio') {
        target.checked = true
      }
    }
    e.preventDefault()
  }
}

const clickFn = (e) => {
  if (e.target.matches(SELECTOR)) {
    LabelNoScroll.perform(e, e.target)
  }
}

export const initLabelNoScroll = () => {
  // 避免在turbolinks:load，重複綁定上去，導致效果有問題
  document.removeEventListener('click', clickFn)
  document.addEventListener('click', clickFn)
}

import { Controller } from "stimulus"
import Dropzone from 'dropzone'
const DROPZONE_SELECTOR = '[data-dropzone][data-printer-type]'

export default class extends Controller {
    static targets = ['overlay', 'disableElement', 'printer',
        'dropzoneContainer', 'priceInput'
    ]
    static classes = ['hidden', 'disable', 'chosen', 'unchosen']

    showTarget() {
        this.overlayTarget.classList.remove(this.hiddenClass);
    }

    hideTarget() {
        this.overlayTarget.classList.add(this.hiddenClass);
    }

    disableBelow() {
        this.disableElementTarget.classList.add(this.disableClass);
    }

    recoverBelow() {
        this.disableElementTarget.classList.remove(this.disableClass);
    }

    choose(e) {
        const chosenPrinterType = e.target.dataset.printer
        this._switchDronzone(chosenPrinterType)
        this._switchChosenPrinterType(e.target)
    }

    setPrice(e){
        if (!e.target.dataset.price) {
            const divWithPriceValue = e.target.closest('[data-price]')
            this.priceInputTarget.value = divWithPriceValue.dataset.price
        }else {
            this.priceInputTarget.value = e.target.dataset.price
        }
    }

    _switchChosenPrinterType(target) {
        this.printerTargets.forEach(target=>this._unchoose(target))
        this._highlightChosen(target)
    }
    _highlightChosen(target) {
        target.classList.remove(...this._unchoseStyles())
        target.classList.add(this.chosenClass)
    }

    _unchoose(target) {
        target.classList.add(...this._unchoseStyles())
        target.classList.remove(this.chosenClass)
    }

    _unchoseStyles() {
        return this.unchosenClass.split(/\s/)
    }

    _switchDronzone(printerType) {
        this._showDropzone(printerType)
        this._enableDropzone(printerType)
    }

    _showDropzone(printerType) {
        this.dropzoneContainerTargets.forEach(target => {
            const targetPrinterType = target.dataset.printer
            if (targetPrinterType === printerType) {
                target.classList.remove(this.hiddenClass)
            }else {
                target.classList.add(this.hiddenClass)
            }
        })
    }

    _enableDropzone(printerType){
        this._dropzoneInstances().forEach(dropzone => {
            const dropzonePrinterType = dropzone.element.getAttribute('data-printer-type')
            if (dropzonePrinterType === printerType) {
                dropzone.enable()
            }else {
                dropzone.disable()
            }
        })
    }

    _dropzoneInstances() {
      const instances = []
      document.querySelectorAll(DROPZONE_SELECTOR).forEach((dom) => {
        instances.push(Dropzone.forElement(dom))
      })
      return instances
    }
}

import TomSelect from 'tom-select'

const SELECTOR = '[data-tags-select]'

// Use in base_form
class TagsSelect {
  constructor(dom) {
    this.dom = dom
    this.instance = null

    this.init()
  }

  init() {
    if (this.dom.dataset.complete === '1') {
      return
    }

    this.instance = new TomSelect(this.dom, {
      plugins: {
        remove_button:{
          title: 'Remove this item',
        }
      },
      highlight: false,
      persist: false,
      create: true,
      openOnFocus: false,
      render: {
        item: function(data, escape) {
          let style = '';
          if (data.style === 'ai') {
            style = 'style="background-color: #2BDEE9; color: #000;"';
          }

          return '<div ' + style + '>' + escape(data.text) + '</div>';
        },
      }
    });
    this.dom.dataset['complete'] = '1'
  }

  destroy() {
    this.dom.dataset['complete'] = null
    this.instance.destroy()
  }
}

let instances = []

export const initTagsSelect = () => {
  document.querySelectorAll(SELECTOR).forEach((dom) => {
    if (dom.dataset.complete !== '1') {
      instances.push(new TagsSelect(dom))
    }
  })
}

export const destroyTagsSelect = () => {
  instances.forEach((instance) => {
    instance.destroy()
  })
}

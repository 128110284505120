class ProfileSingleFileDropzone {
  constructor(selector) {
    this.dropzoneForm = document.querySelector(selector);
    this.isDragged = false;
    this.bindEvents();
  }

  toggleDragClass(isDraggedStatus) {
    this.dropzoneForm.classList.toggle("dz-drag-hover", isDraggedStatus);
    this.isDragged = isDraggedStatus;
  }

  bindEvents() {
    this.dropzoneForm.addEventListener("dragenter", () => this.toggleDragClass(true));
    this.dropzoneForm.addEventListener("dragleave", () => this.toggleDragClass(false));
    this.dropzoneForm.addEventListener("drop", () => this.toggleDragClass(false));
  }
}

export const initProfileSingleFileDropzone = (selector) => {
  new ProfileSingleFileDropzone(selector);
}
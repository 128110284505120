import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import * as Sentry from "@sentry/browser";
import i18next from 'i18next';

const SELECTORS = {
  CONTAINER: '#post-variant-sortable',
  TOGGLE_BTN: '#toggle-sorting',
  NESTED_FIELDS: '.nested-fields',
  VARIANT_FORM: '.variant-form',
  VARIANT_SORTED: '.variant-sorted',
  POSITION_INPUT: 'input.variant-position',
  POSITION_DISPLAY: '.variant-sorted .variant-position',
  VARIANT_NAME: '.file-group-type .ts-control .items .item',
  FILE_GROUPS: '.ts-control.file-type-select .items .item',
  PRICES: {
    BASE: 'input[name$="[price]"]',
    ORIGINAL: 'input[name$="[original_price]"]'
  }
};

class VariantSortManager {
  constructor() {
    this.sortingMode = false;
    this.sortable = null;
    this.container = document.querySelector(SELECTORS.CONTAINER);
  }

  init() {
    if (!this.container) return;

    this.initSortable();
    this.initEventListeners();
  }

  initSortable() {
    this.sortable = new Sortable(this.container, {
      animation: 150,
      ghostClass: 'bg-grey-7',
      filter: '.disabled',
      disabled: !this.sortingMode,
      onEnd: () => this.reorderVariantPosition()
    });
  }

  initEventListeners() {
    const toggleBtn = document.querySelector(SELECTORS.TOGGLE_BTN);
    if (toggleBtn) {
      toggleBtn.addEventListener('click', () => this.toggleSortingMode());
    }

    document.addEventListener('click', (e) => this.handleSortingButtons(e));
  }

  handleSortingButtons(event) {
    const target = event.target.closest('.to-top, .to-bottom');
    if (!target) return;

    const nestedField = target.closest(SELECTORS.NESTED_FIELDS);
    if (!nestedField) return;

    if (target.classList.contains('to-top')) {
      this.moveItem(nestedField, 'top');
    } else if (target.classList.contains('to-bottom')) {
      this.moveItem(nestedField, 'bottom');
    }
  }

  moveItem(item, position) {
    if (position === 'top') {
      const firstItem = this.container.firstElementChild;
      if (item !== firstItem) {
        this.container.insertBefore(item, firstItem);
      }
    } else {
      const lastItem = this.container.lastElementChild;
      if (item !== lastItem) {
        this.container.appendChild(item);
      }
    }
    
    this.reorderVariantPosition();
  }

  toggleSortingMode() {
    this.sortingMode = !this.sortingMode;
    this.sortable.option('disabled', !this.sortingMode);
    this.updateUI();
  }

  updateUI() {
    const nestedFields = document.querySelectorAll(SELECTORS.NESTED_FIELDS);
    
    nestedFields.forEach((field, index) => {
      const variantForm = field.querySelector(SELECTORS.VARIANT_FORM);
      const variantSorted = field.querySelector(SELECTORS.VARIANT_SORTED);

      if (this.sortingMode) {
        this.updateSortedView(field, variantForm, variantSorted);
      }

      this.toggleViews(variantForm, variantSorted);
    });
  }

  updateSortedView(field, variantForm, variantSorted) {
    this.reorderVariantPosition()
    this.updateVariantName(variantForm, variantSorted);
    this.updateFileGroups(variantForm, variantSorted);
    this.updatePrices(variantForm, variantSorted);
  }

  updateVariantName(variantForm, variantSorted) {
    const nameItem = variantForm.querySelector(SELECTORS.VARIANT_NAME);
    const nameSpan = variantSorted.querySelector('.variant-name-item');
    if (nameSpan && nameItem) {
      nameSpan.textContent = nameItem.textContent.trim();
    }
  }

  updateFileGroups(variantForm, variantSorted) {
    const fileGroupItems = variantForm.querySelectorAll(SELECTORS.FILE_GROUPS);
    const fileGroupContainer = variantSorted.querySelector('.file-group');
    
    if (!fileGroupContainer) return;

    const fileGroupNames = Array.from(fileGroupItems)
      .map(item => this.extractTextContent(item));

    this.renderFileGroups(fileGroupContainer, fileGroupNames);
  }

  extractTextContent(element) {
    return Array.from(element.childNodes)
      .filter(node => node.nodeType === Node.TEXT_NODE)
      .map(node => node.textContent.trim())
      .join('');
  }

  renderFileGroups(container, names) {
    container.innerHTML = `${i18next.t('models.variant_fields.file_group_ids')} :`;
    
    names.forEach(name => {
      const span = document.createElement('span');
      span.className = 'file-group-item inline-block rounded bg-grey-1 mx-1 px-4 py-1 my-1 text-12px';
      span.textContent = name;
      container.appendChild(span);
    });
  }

  updatePrices(variantForm, variantSorted) {
    const prices = {
      base: variantForm.querySelector(SELECTORS.PRICES.BASE)?.value || '',
      original: variantForm.querySelector(SELECTORS.PRICES.ORIGINAL)?.value || ''
    };

    const priceSpans = {
      base: variantSorted.querySelector('.base-price'),
      original: variantSorted.querySelector('.original-price')
    };

    if (priceSpans.base) priceSpans.base.textContent = prices.base;
    if (priceSpans.original) priceSpans.original.textContent = prices.original;
  }

  toggleViews(variantForm, variantSorted) {
    if (variantForm) {
      variantForm.classList.toggle('hidden', this.sortingMode);
    }
    if (variantSorted) {
      variantSorted.classList.toggle('hidden', !this.sortingMode);
    }
  }

  reorderVariantPosition() {    
    VariantSortManager.reorderVariantPosition(this.container);
  }

  static reorderVariantPosition(container = null) {
    const targetContainer = container || document.querySelector(SELECTORS.CONTAINER);
    if (!targetContainer) return;
    
    const items = targetContainer.querySelectorAll(SELECTORS.NESTED_FIELDS);
    
    items.forEach((item, index) => {
      const position = index + 1;
      const positionInput = item.querySelector(SELECTORS.POSITION_INPUT);
      const positionDisplay = item.querySelector(SELECTORS.POSITION_DISPLAY);
      if (positionInput) positionInput.value = position;
      if (positionDisplay) positionDisplay.textContent = position;
    });
  }
}

export const initVariantSortable = () => {
  const manager = new VariantSortManager();
  manager.init();
};

export const reorderVariantPosition = VariantSortManager.reorderVariantPosition;
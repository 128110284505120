// FIXME 目前沒在用
const scrollToAnchor = () => {
  const url = window.location
  const params = new URLSearchParams(url.search)
  for (let pair of params.entries()) {
    if(pair[0] === 'anchor') {
      const anchor = document.getElementById(pair[1])
      if(anchor) {
        const offsetPosition = anchor.getBoundingClientRect().top + window.pageYOffset - 64;
        window.scrollTo({top: offsetPosition});
      }
    }
  }
}

export default scrollToAnchor

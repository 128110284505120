import { Controller } from "stimulus"
import Swiper, { Navigation, Pagination } from 'swiper';

// 用在首頁的image slide
export default class extends Controller {
  static targets = ['swiper', 'swiperPrev', 'swiperNext']

  swiper = null
  
  connect() {
    console.log('connect' );
    this.swiper = new Swiper(this.swiperTarget, {
      modules: [Navigation, Pagination],
      direction: 'horizontal',
      loop: true,
      slidesPerView: 3,
      centeredSlides: true,
      spaceBetween: 12,
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        750: {
          slidesPerView: 4,
          spaceBetween: 96,
        },
        1100: {
          slidesPerView: 4,
          spaceBetween: 160,
        },
        1500: {
          slidesPerView: 6,
          spaceBetween: 160,
        }
      }
    });
  }
  disconnect() {
    this.swiper.destroy()
    this.swiper = null
  }
}

const SELECTOR = '[data-password-eye]'
const SHOW_ATTR = '[data-show]'

class PasswordEye {
  static toggle(element) {
    const $showDom = this._findPasswordDom(element)
    const show = $showDom.dataset.show === 'true'
    $showDom.dataset.show = !show
    $showDom.setAttribute('type', show ? 'password' : 'text')
  }

  static _findPasswordDom(element) {
    return element.parentNode.querySelector(SHOW_ATTR)
  }
}

const clickFn = (e) => {
  if (e.target.matches(SELECTOR)) {
    PasswordEye.toggle(e.target)
  }
}

export const initPasswordEye = () => {
  document.removeEventListener('click', clickFn)
  document.addEventListener('click', clickFn)
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['cartItemCount']

  setCartItemCount(count){
    this.cartItemCountTarget.innerText = count
    this.cartItemCountTarget.classList.remove("hidden")
  }

  updateCart(e) {
    const cartItemCount = e.detail.cart_item_count
    this.setCartItemCount(cartItemCount)
  }

}


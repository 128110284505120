import parseTweet from 'twitter-text/dist/parseTweet'
import { throttle } from './tools'

const SELECTOR = '[data-detect-text-size]'
const TARGET_ATTRIBUTE = 'target'
const THROTTLE_PERIOD = 500

class DetectTextSize {
  constructor(dom) {
    this.dom = dom
    if (dom.dataset[TARGET_ATTRIBUTE]) {
      this.target = document.querySelector(this.dom.dataset[TARGET_ATTRIBUTE])
    }

    this.init()
  }

  init() {
    if (this.target) {
      this._performParse(this.target)
      this._bindInputEvent()
      this.bindSubmitEvent();
      this.toggleErrorVisibility(this.target); 
    }
  }

  _bindInputEvent() {
    if (this.target) {
      this.target.addEventListener('input', throttle(THROTTLE_PERIOD)((e) => {
        this._performParse(e.currentTarget)
      }))
      this.target.addEventListener('change', (e) => {
        this._performParse(e.currentTarget)
      })
    }
  }
  
  bindSubmitEvent() {
    const form = document.getElementById('profile-form') ;
    if (form) {
      form.addEventListener('submit', () => {
        const length = parseTweet(this.target.value).weightedLength;
        if (length > 600) {
          this.toggleErrorVisibility(length); 
        }
      });
    }
  }

  _updateView(length) {
    this.dom.textContent = length
  }

  _performParse(target) {
    const length = parseTweet(target.value).weightedLength
    this._updateView(length)
    if(this.dom.dataset[TARGET_ATTRIBUTE] === '#profile_introduce' && length <= 600 ){
      const errorElement = document.getElementById('profile-bio-size-error');
      errorElement.classList.add('hidden')
    }
  }

  toggleErrorVisibility(target) {
    const errorElement = document.getElementById('profile-bio-size-error');
    if (this.dom.dataset[TARGET_ATTRIBUTE] === '#profile_introduce') {
      const isOverLimit = parseTweet(target.value).weightedLength > 600;
      errorElement.classList.toggle('hidden', !isOverLimit);
    }
    if(!errorElement.classList.contains('hidden')){
      errorElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

export const initDetectTextSize = () => {
  document.querySelectorAll(SELECTOR).forEach((dom) => {
    new DetectTextSize(dom)
  })
}

import { Controller } from "stimulus"
import Swiper, { Autoplay, Navigation } from 'swiper';

// 用在首頁的image slide
export default class extends Controller {
  static targets = ['swiper', 'swiperPrev', 'swiperNext']

  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      modules: [ Autoplay, Navigation ],
      slidesPerView: 'auto',
      spaceBetween: 20,
      loop: true,
      navigation: {
        nextEl: document.getElementById('home-tag-slider-next'),
        prevEl: document.getElementById('home-tag-slider-prev')
      }
    });
  }
  disconnect() {
    this.swiper.destroy()
    this.swiper = null
  }
}


import { Controller } from "stimulus"

export default class extends Controller {

  checkAdvPostStatus(event) {
    event.preventDefault()
    const element = event.currentTarget;
    const postId = element.dataset.modelIdValue;
    const postState = element.dataset.modelStateValue;
    // simple 跟 advanced 都直接送審不需popup警告
    this.submitAction(postId, postState)
  }
  
  submitAction(postId, postState){
     const requestUrl = `/profile/models/${postId}/ask_for_submit?state=${postState}`

     fetch(requestUrl, {
       method: 'POST',
       headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          "Content-Type": "application/json",
       },
     })
     .then(response => {
       if(response.ok) {
        location.reload();
       }
       throw new Error('Request failed.');
     })
     .catch(error => {
       console.error('Error:', error);
     });
  }

  simpleSubmitAction(event){
    const element = event.currentTarget;
    const postId = element.dataset.id;
    const postState = element.dataset.state;
    this.submitAction(postId, postState)
  }
}


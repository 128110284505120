import $ from 'jquery'

export const requiredInputValidator = (dom) => {
  $(dom).find("form[data-submit-validate]").each(function(item) {
    beforeSubmit(this)

    $(this).find(":input[required], textarea[required]").each(function(){
      $(this).on('change', function(){
        beforeSubmit(this.form)
        hiddenError(this)
      })
      $(this).on('input', function(){
        beforeSubmit(this.form)
        hiddenError(this)
      })
    })
  })
}

export function beforeSubmit(form){
  const allInputs = $(form).find(':input:visible[required], textarea[required]')
  let anyInputEmpty = false

  for(const input of allInputs) {
    const isEmpty = input.value === ''

    anyInputEmpty = anyInputEmpty || isEmpty
  }

  const allCheckboxes = $(form).find(':input[type=checkbox][required]')
  let anyCheckboxFalse = false

  for(const checkbox of allCheckboxes) {
    anyCheckboxFalse = anyCheckboxFalse || !checkbox.checked
  }

  // dropzone select
  const allSelects = $(form).find("select[hidden][data-required='true']").not('form .hidden select')
  let anySelectFalse = false

  for(const select of allSelects) {
    const isEmpty = select.value === ''
    anySelectFalse = anySelectFalse || isEmpty
  }

  // recaptcha
  let recapthaPass = true
  if($(form).attr('data-recaptcha') !== undefined) {
    recapthaPass = $('#g-recaptcha-response').val() != ''
  }

  if ( !anyInputEmpty && !anyCheckboxFalse && !anySelectFalse && recapthaPass) {
    $(form).find(':input[type=submit]').prop('disabled', false)
  } else {
    $(form).find(':input[type=submit]').prop('disabled', true)
  }
}

function hiddenError(input) {
  const parentEl = $(input).parents('.error')[0]

  if (parentEl == undefined) {
    return
  }

  const errorEls = $(parentEl).children('.error-msg')

  for(const errorEl of errorEls) {
    errorEl.classList.add('hidden')
  }

  parentEl.classList.remove('error')
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import 'css/application.scss'
import 'select2'

require.context('../images', true)
require("@nathanvda/cocoon")

Rails.start()
ActiveStorage.start()

import { initSlide } from 'components/slide'
import { initMobileToolBar } from 'components/mobile_toolbar'
import { initPasswordEye } from 'components/password_eye'
import scrollToTop from 'components/scrollToTop'
import { initDropZone } from 'components/dropzone'
import { initHeaderSearch } from 'components/header_search'
import { initUniformSelect } from 'components/uniform_select'
import { initDetectTextSize } from 'components/detect_text_size'
import { initLabelNoScroll } from 'components/label_no_scroll'
import { likePost } from 'posts/like_post'
import { savePost } from 'posts/save_post'
import { initAutoSubmit } from 'components/auto_submit'
import { initImagePreview } from 'components/image_preview'
import scrollToAnchor from 'components/scroll_to_anchor';
import { initSentry } from 'components/sentry';
import { initSelect2FileGroup } from 'components/tagsSelect2';
import { initCategorySelect } from 'components/category_select';
import { initFormValidate } from "../components/form_validate"
import { requiredInputValidator, beforeSubmit } from "../components/required_input_validator"
import { initSearchbarBtn } from "../components/searchbar_btn"
import { initTagsSelect } from 'components/tags_select'
import { initVariantSortable, reorderVariantPosition } from 'components/post_variant'
import { contentEllipsis } from 'components/content_ellipsis'
import { preventDownload } from 'components/prevent_download'
import { initProfileSingleFileDropzone } from 'components/profile_single_file_dropzone'
import { initDynamicDropdown } from 'components/dynamic_dropdown';
import { initErrorInputKlass } from 'components/uniform_input_klass';
import { initI18n } from "components/i18n";
import { initTranslateContent } from "components/translate";
import { initExchangeRates } from "components/exchange_rates";
import { initFooterAnimation } from "components/footer_animation";
import { trackingEvent } from "components/tracking_event";
import { isFirefox } from "components/is_firefox";

import LazyLoad from "vanilla-lazyload";

initI18n()

document.addEventListener('DOMContentLoaded', (event) => {
  initSentry()
  
  const lazyLoadInstance = new LazyLoad()

  initSlide()
  initMobileToolBar()
  initPasswordEye()
  scrollToTop()
  scrollToAnchor()
  initDropZone()
  initHeaderSearch('header-search-form')
  initUniformSelect()
  initDetectTextSize()
  initLabelNoScroll()
  likePost()
  savePost()
  initAutoSubmit()
  initImagePreview()
  initFormValidate()
  initSearchbarBtn('.w-account-container')
  initTagsSelect()
  initVariantSortable()
  reorderVariantPosition()
  contentEllipsis()
  preventDownload()
  initDynamicDropdown()
  initTranslateContent()
  initExchangeRates()
  initFooterAnimation()
})

// for use js.erb to open modal
document.addEventListener('ajax:success', () => {
  initSlide()
  initDropZone()
  initUniformSelect()
  initDetectTextSize()
})

window.trackingEvent = trackingEvent

window.initSelect2FileGroup = initSelect2FileGroup
window.initCategorySelect = initCategorySelect
window.initUniformSelect = initUniformSelect
window.initFormValidate = initFormValidate
window.requiredInputValidator = requiredInputValidator
window.beforeSubmit = beforeSubmit
window.initErrorInputKlass = initErrorInputKlass
window.reorderVariantPosition = reorderVariantPosition
window.contentEllipsis = contentEllipsis
window.initProfileSingleFileDropzone = initProfileSingleFileDropzone
window.initFooterAnimation = initFooterAnimation
window.isFirefox = isFirefox
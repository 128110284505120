const SELECTOR = '[data-image-preview]'
const IMAGE_YES_SELECTOR = '[data-image="yes"]'
const IMAGE_NO_SELECTOR = '[data-image="no"]'
const MAIN_IMAGE_SELECTOR = '[data-main-image]'
const IMAGE_REMOVE_SELECTOR = '[data-image-remove]'

class ImagePreview {
  constructor(dom) {
    this.dom = dom
    this.imageYes = dom.parentNode.querySelector(IMAGE_YES_SELECTOR)
    this.imageNo = dom.parentNode.querySelector(IMAGE_NO_SELECTOR)
    this.removeDom = dom.parentNode.querySelector(IMAGE_REMOVE_SELECTOR)

    this.bindEvents()
  }

  bindEvents() {
    this.dom.addEventListener('change', (e) => {
      const url = URL.createObjectURL(e.currentTarget.files[0])
      const img = this.imageYes.querySelector(MAIN_IMAGE_SELECTOR)
      img.src = url
      img.alt = ""
      this.imageYes.classList.remove('hidden')
      this.imageNo.classList.add('hidden')
      this.removeDom.checked = false
    })
    this.removeDom.addEventListener('change', (e) => {
      if (this.removeDom.checked === true) {
        this.dom.value = ''
      }
    })
  }
}

export const initImagePreview = () => {
  document.querySelectorAll(SELECTOR).forEach((dom) => {
    new ImagePreview(dom)
  })
}

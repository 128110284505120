const savePost = () => {
  const saveForms = document.querySelectorAll("#post-detail .post-save");

  saveForms.forEach((element) => {
    element.addEventListener("ajax:success", (event) => {
      const [data, status, xhr] = event.detail;
      if (data['status'] === 'saved'){
        element.classList.add('focus')
      } else {
        element.classList.remove('focus')
      }

      var savedCountEl = document.getElementById('post-saved-count')
      var savedCount   = parseInt(data['count'])

      if(savedCountEl && savedCount != NaN) {
        savedCountEl.innerText = savedCount;
      }
    });
  });
}

export { savePost };

import { Controller } from "stimulus"
import Swiper, { Autoplay, Navigation, Pagination, Lazy } from 'swiper';

// 用在首頁上方的banner
export default class extends Controller {
  static targets = ['swiper', 'swiperPrev', 'swiperNext']

  swiper = null
  
  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      modules: [ Autoplay, Navigation, Pagination, Lazy ],
      direction: 'horizontal',
      lazy: {
        loadPrevNext: true,
      },
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: this.swiperNextTarget,
        prevEl: this.swiperPrevTarget,
      }
    });
  }
  disconnect() {
    this.swiper.destroy()
    this.swiper = null
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    btnBaseClasses: String
  }
  static targets = ['btn']
  connect() {
    this.addClasses()
  }

  addClasses() {
    this.btnTarget.className += ` ${this.btnBaseClassesValue}`
  }

  afterSuccess(e) {
    let [data, status, xhr] = e.detail
    let resp = xhr.response
    this.element.innerText = ''
    this.element.insertAdjacentHTML('afterbegin', resp)
    this.addClasses()
  }
}

import { format } from 'date-fns'
import * as Sentry from "@sentry/browser";

export const currencyFormat = {
  'USD': {unit: '$', decimal: 2},
  'JPY': {unit: '¥', decimal: 0},
  'TWD': {unit: 'NT$', decimal: 0},
}
export const formatCurrency = (price) => {
  const exchangeRatesData = localStorage.getItem('exchangeRatesData');
  let currency = window.global_values['CURRENCY'];
  let rate = null;

  // 交易對格式 USDJPY,USDTWD
  // 沒有匯率的話就用 USD
  if(exchangeRatesData) {
    rate = JSON.parse(exchangeRatesData).rates[`USD${currency}`]['Exrate']
  } else {
    currency = 'USD'
    rate = 1
  }
  const { unit, decimal } = currencyFormat[currency];
  return `${unit} ${(price * rate).toFixed(decimal)}`
}

export const initExchangeRates = async () => {
  const exchangeRatesData = localStorage.getItem('exchangeRatesData');
  if (!exchangeRatesData || exchangeRatesData.updated_at !== format(new Date(), 'yyyy-MM-dd')) {
    try {
      const response = await fetch('/api/v1/exchange_rates');
      if (response.ok) {
        const data = await response.json();
  
        const today = format(new Date(), 'yyyy-MM-dd');
        localStorage.setItem('exchangeRatesData', JSON.stringify({rates: data, updated_at: today}));
      }
    } catch (error) {
      Sentry.captureException(`fetch exchange rate error: ${error}`);
    }
  }
}

import { Controller } from "stimulus"

const SHARE_ON_FACEBOOK = "[data-share-facebook]"
const SHARE_ON_TWITTER = "[data-share-twitter]"
const SHARE_ON_WHATSAPP = "[data-share-whatsapp]"
const SHARE_ON_REDDIT = "[data-share-reddit]"
const SHARE_ON_PINTEREST = "[data-share-pinterest]"
const SHARE_ON_EMAIL = "[data-share-email]"
const SHARE_ON_COPY = "[data-share-copy]"
export default class extends Controller {
  static values = {
    show: String,
    defaultTemplate: String
  }
  static targets = ['content', 'mask']
  showModal = false
  modalTemplate = null

  // normal use
  // <div data-controller="modal">
  //   <a href="#"
  //      class="btn btn-primary inline-block"
  //      data-action="modal#renderModalHandler"
  //      data-template="localeSwitchTemplate">
  //     en
  //   </a>
  //   <template id="localeSwitchTemplate"></template>
  //   <div data-modal-target="content"></div>
  // </div>

  connect() {
    this.element[this.identifier] = this
    /*  當modal要直接顯示時
      <div class="flex justify-end mb-4"
        data-controller="modal"
        data-modal-show-value="true"
        data-modal-default-template-value="paypalAlertTemplate"
      >
    */
    if(this.showValue === 'true' && this.defaultTemplateValue !== '') {
      const template = document.getElementById(this.defaultTemplateValue).innerHTML
      this.renderModal(template)
    }
  }
  renderModalHandler(e) {
    e.preventDefault()
    const templateName = e.currentTarget.dataset.template
    if(templateName) {
      const templateId = e.currentTarget.dataset.template
      const template = document.getElementById(templateId).innerHTML
      this.renderModal(template)
    }
  }
  renderPopupModalHandler(e) {
    e.preventDefault()
    const templateName = e.currentTarget.dataset.template
    if(templateName) {
      const templateId = e.currentTarget.dataset.template
      const template = document.getElementById(templateId).innerHTML
      this.renderModal(template)
    }
    window.initUniformSelect()
  }
  renderModal(template) {
    this.showModal = true
    this.contentTarget.insertAdjacentHTML("afterBegin", template)

    // 跨sitmulus controller 關閉modal event
    // 在其他controller 使用以下方式關閉modal
    // const event = new CustomEvent("closeModal", {});
    // window.dispatchEvent(event);
    window.addEventListener("modalClose", this.closeModal.bind(this));
  }

  renderShareModalHandler(e) { 
    e.preventDefault()
    const templateId = e.target.dataset.template
    const template = document.getElementById(templateId).innerHTML
    this.renderModal(template)
    this.shareOnFacebook()
    this.shareOnTwitter()
    this.shareOnWhatsapp()
    this.shareOnReddit()  
    this.shareOnPinterest()  
    this.shareOnEmail()
    this.shareOnCopy()
  }

  shareOnFacebook () {
    const $elements = document.querySelectorAll(SHARE_ON_FACEBOOK)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement

        FB.ui({
          method: "share",
          href: postEl.dataset.link,
        })
      })
    )
  }

  shareOnTwitter() {
    const $elements = document.querySelectorAll(SHARE_ON_TWITTER)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement

        window.open(
          `https://twitter.com/share?url=${postEl.dataset.link}&text=${postEl.dataset.title}`,
          "_blank"
        )
      })
    )
  }

  shareOnWhatsapp = () => {
    const $elements = document.querySelectorAll(SHARE_ON_WHATSAPP)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement

        window.location.href = "https://api.whatsapp.com/send?text=" + postEl.dataset.link
      })
    )
  }

  shareOnReddit() {
    const $elements = document.querySelectorAll(SHARE_ON_REDDIT)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement

        window.open(
          `https://reddit.com/submit?url=${encodeURIComponent(postEl.dataset.link)}&title=${postEl.dataset.title}`,
          "_blank"
        )
      })
    )
  }

  shareOnPinterest() {
    const $elements = document.querySelectorAll(SHARE_ON_PINTEREST)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement
        
        window.open(
          `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(postEl.dataset.link)}&media=${encodeURIComponent(postEl.dataset.image)}&description=${encodeURIComponent(postEl.dataset.title)}`,
          "_blank"
        )
      })
    )
  }

  shareOnEmail = () => {
    const $elements = document.querySelectorAll(SHARE_ON_EMAIL)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement

        window.location.href = `mailto:?subject=${postEl.dataset.title}&body=${postEl.dataset.link}`
      })
    )
  }

  shareOnCopy = () => {
    const $elements = document.querySelectorAll(SHARE_ON_COPY)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        e.target.firstElementChild.style.visibility = "visible"
        setTimeout(function () {
          e.target.firstElementChild.style.visibility = "hidden"
        }, 1000)

        const copyArea = e.target.parentElement.parentElement.dataset.link
        navigator.clipboard.writeText(copyArea)
      })
    )
  }

  // home page control gradient
  showGradient = () => {
    this.maskTarget.classList.add('block')
  }

  hideGradient = () => {
    if(!this.showModal){
      this.maskTarget.classList.remove('block')
    }
  }

  closeModal = () => {
    this.showModal = false
    this.contentTarget.innerHTML = ''
  }
}

import { Controller } from "stimulus"
import Swiper, { Autoplay, Navigation, Pagination, Lazy } from 'swiper';

// 用在首頁上方的banner
export default class extends Controller {
  static targets = ['swiper', 'swiperPrev', 'swiperNext']

  swiper = null
  
  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      modules: [ Autoplay, Navigation, Pagination, Lazy ],
      direction: 'horizontal',
      lazy: {
        loadPrevNext: true,
      },
      loop: true,
      slidesPerView: 1,
      spaceBetween: 12,
      centeredSlides: true,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: this.swiperNextTarget,
        prevEl: this.swiperPrevTarget,
      },
      on: {
        slideChange: () => {
          if (this.swiper && this.swiper.activeIndex > 2) {
            this.loadRemainingBackgrounds();
          }
        }
      }
    });
    this.initLazyLoading();
  }

  initLazyLoading() {
    const slides = this.element.querySelectorAll('.lazy-background');
    slides.forEach((slide, index) => {
      if (index < 3) {
        this.loadBackground(slide);
      }
    });
  }

  loadBackground(slide) {
    const bgUrl = slide.dataset.background;
    if (!bgUrl) {
      console.error('background image not found');
      slide.style.backgroundImage = '';
      return;
    }
    slide.style.backgroundImage = `url(${bgUrl})`;
  }

  loadRemainingBackgrounds() {
    const slides = this.element.querySelectorAll('.lazy-background');
    slides.forEach((slide, index) => {
      if (index >= 3 && !slide.style.backgroundImage) {
        this.loadBackground(slide);
      }
    });
  }
  disconnect() {
    this.swiper.destroy()
    this.swiper = null
  }
}

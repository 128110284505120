class DynamicDropdown {
  constructor(triggerElement, targetPanel){
    this.triggerElement = triggerElement
    this.targetPanel = targetPanel
    if (!this.triggerElement || !this.targetPanel) {
      console.error('DynamicDropdown: Trigger or panel element not found.');
      return;
    }

    this.bindMethods(['toggleDisplay', 'handleScroll', 'positionDropdown', 'hideDropdown']);
    this.bindEventHandlers(this.triggerElement)
  }

  bindMethods(methodNames) {
    methodNames.forEach(methodName => {
      this[methodName] = this[methodName].bind(this);
    });
  }

  bindEventHandlers(triggerElement) {
    triggerElement.addEventListener('click', this.toggleDisplay);
    window.addEventListener('scroll', this.handleScroll);
  }


  handleScroll = () => {
    if (!this.triggerElement.classList.contains('close')) {
      this.hideDropdown();
    }
  };

  positionDropdown() {
    if (!this.triggerElement || !this.targetPanel || this.triggerElement.classList.contains('close')) {
      return;
    }

    const rect = this.triggerElement.getBoundingClientRect();
    const absoluteTop = rect.top + 25;
    const absoluteLeft = rect.left + 15;
    this.targetPanel.style.position = 'fixed';
    this.targetPanel.style.top = `${absoluteTop}px`;
    this.targetPanel.style.left = `${absoluteLeft}px`;
  }

  toggleDisplay(e) {
    e.stopPropagation()
    this.triggerElement.classList.toggle('close')
    this.targetPanel.classList.toggle('hidden')
    this.positionDropdown();
  }

  hideDropdown() {
    this.triggerElement.classList.add('close');
    this.targetPanel.classList.add('hidden');
  }

  destroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}


export const initDynamicDropdown = () => {
   const targetArray = document.querySelectorAll('[data-dropdown-id]')
   if(targetArray){
    targetArray.forEach(trigger => {
      const panelId = trigger.getAttribute('data-dropdown-id') + '-panel';
      const panel = document.querySelector(`[data-nav-dropdown-panel-target="${panelId}"]`);
      if (panel) {
        new DynamicDropdown(trigger, panel);
      }
    });
   }
}


import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'
import { get, post, put, patch, destroy } from '@rails/request.js'

export default class extends Controller {
  static targets = ['maskArea', 'member', 'unreadCommentCount', 'unreadActionCount', 'unreadSystemCount', 'unreadCount',
                    'commentNotificationsBtn', 'commentNotificationsSection',
                    'actionNotificationsBtn', 'actionNotificationsSection',
                    'systemNotificationsBtn', 'systemNotificationsSection']

  static values = {
    readUrl: String,
    unreadCommentCount: Number,
    unreadActionCount: Number,
    unreadSystemCount: Number,
    unreadCount: Number,
    displayType: String
  }

  connect() {
    this.displayTypeValue = 'comment'

    document.body.addEventListener('click', () => {
      this.hiddenDisplay()
    })
    document.getElementById('mobile-notify').addEventListener('click', (e) => {
      this.notifyBtnClicked(e)
    })

    const readAllBtns = $('.notify-wrap .readall')
    for(let i = 0; i < readAllBtns.length; i++) {
      const btn = readAllBtns[i]
      btn.addEventListener('click', (e) => {
        this.readAllBtnClicked(e)
      })
    }

    const notificationLinks = $('a.notification-link, .notification-link > a')
    for(let i = 0; i < notificationLinks.length; i++) {
      const link = notificationLinks[i]
      link.addEventListener('click', (e) => {
        e.preventDefault();

        this.notificationItemClicked(link)
      })
    }
  }

  unreadCountValueChanged() {
    this.unreadCountTargets.forEach(target => {
      if (this.unreadCountValue === 0) {
        target.classList.add('hidden')
      } else {
        target.classList.remove('hidden')
      }

      const unreadCountText = (this.unreadCountValue > 9) ? '9+' : this.unreadCountValue
      target.innerText = unreadCountText
    })

    const mobileUnreadCount = document.getElementById('mobile-notifications-unread-count')
    if (mobileUnreadCount) {
      if (this.unreadCountValue === 0) {
        mobileUnreadCount.classList.add('hidden')
      } else {
        mobileUnreadCount.classList.remove('hidden')
      }
      const mobileUnreadCountText = (this.unreadCountValue > 9) ? '9+' : this.unreadCountValue
      mobileUnreadCount.innerText = mobileUnreadCountText
    }
  }

  displayTypeValueChanged() {
    const readAll = document.querySelector('.readall')

    if (this.displayTypeValue == 'comment' && this.unreadCommentCountValue > 0) {
      readAll.classList.remove('hidden')
    } else if (this.displayTypeValue == 'action' && this.unreadActionCountValue > 0) {
      readAll.classList.remove('hidden')
    } else if (this.displayTypeValue == 'system' && this.unreadSystemCountValue > 0) {
      readAll.classList.remove('hidden')
    } else {
      readAll.classList.add('hidden')
    }
  }

  async sendReadAllRequest(e) {
    // 挑出指定類型
    const items = this.memberTargets.filter(ele =>
      ele.dataset.notificationType == this.displayTypeValue
    )

    const unreadIds = items.map(function(ele){
      ele.dataset.id
    })

    if(unreadIds.length > 0){
      const resp = await patch(this.readUrlValue, {body: JSON.stringify({ids: unreadIds})})

      if(resp.ok){
        this.unreadCountValue -= unreadIds.length
        this[`unread${this.capitalize(this.displayTypeValue)}CountValue`] = 0

        // remove notifications & hr element & readall btn
        const section = `#unread_${this.displayTypeValue}_notifications-settings`

        $(`${section} .notification-item`).remove();
        $(`${section} hr.border-grey-3`).remove();

        document.querySelector('.readall').classList.add('hidden')
        // add notify empty msg back
        document.querySelector(`${section} .unread-notification-empty-msg`).classList.remove('hidden')
      }
    }
  }

  async sendReadRequest(link) {
    const ele = link.closest('.notification-item')
    const resp = await patch(this.readUrlValue, {body: JSON.stringify({ids: [ ele.dataset.id ]})})

    if(resp.ok){
      ele.dataset.readat = Date.now()
      window.location = link.href
    }
  }

  notifyBtnClicked(e) {
    e.preventDefault()
    e.stopPropagation()
    this.hiddenModal()
    this.toggleDisplay()
  }

  readAllBtnClicked(e) {
    this.sendReadAllRequest()
  }

  notificationItemClicked(link) {
    this.sendReadRequest(link)
  }

  hiddenDisplay() {
    this.element.classList.add('close')
    this.maskAreaTarget.classList.add('hidden')
  }

  toggleDisplay() {
    this.element.classList.toggle('close')
    this.maskAreaTarget.classList.toggle('hidden')
  }

  hiddenModal() {
    const modal = document.querySelector('input.dropdown')
    if (modal) {
      modal.checked = false
    }
  }

  capitalize(str) {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  }

  switchToCommentNotifications(e){
    e.preventDefault()
    e.stopPropagation()

    this.displayTypeValue = 'comment'

    this.commentNotificationsBtnTarget.classList.add('active')
    this.commentNotificationsSectionTarget.classList.remove('hidden')

    this.actionNotificationsBtnTarget.classList.remove('active')
    this.actionNotificationsSectionTarget.classList.add('hidden')

    this.systemNotificationsBtnTarget.classList.remove('active')
    this.systemNotificationsSectionTarget.classList.add('hidden')
  }
  switchToActionNotifications(e){
    e.preventDefault()
    e.stopPropagation()

    this.displayTypeValue = 'action'

    this.commentNotificationsBtnTarget.classList.remove('active')
    this.commentNotificationsSectionTarget.classList.add('hidden')

    this.actionNotificationsBtnTarget.classList.add('active')
    this.actionNotificationsSectionTarget.classList.remove('hidden')

    this.systemNotificationsBtnTarget.classList.remove('active')
    this.systemNotificationsSectionTarget.classList.add('hidden')
  }
  switchToSystemNotifications(e){
    e.preventDefault()
    e.stopPropagation()

    this.displayTypeValue = 'system'

    this.commentNotificationsBtnTarget.classList.remove('active')
    this.commentNotificationsSectionTarget.classList.add('hidden')

    this.actionNotificationsBtnTarget.classList.remove('active')
    this.actionNotificationsSectionTarget.classList.add('hidden')

    this.systemNotificationsBtnTarget.classList.add('active')
    this.systemNotificationsSectionTarget.classList.remove('hidden')
  }
}

const SELECTOR = '#mobile-toolbar'
const ICONS = '.js-toolbar-icon'
class MobileToolBar{
    constructor($dom){
      this.$dom = $dom
      this.icons = this.$dom.querySelectorAll(ICONS)

      this.bindEvents()
    }

    setupActiveStatus(e){
      this.icons.forEach((item) => {
        item.classList.remove('active')
      })

      e.currentTarget.classList.add('active')
    }

    bindEvents(){
      this.icons.forEach((icon) => {
        icon.addEventListener('click', (e) => {
          this.setupActiveStatus(e)
        })
      })
    }
}


export const initMobileToolBar = () => {
    document.querySelectorAll(SELECTOR).forEach(($dom) => new MobileToolBar($dom));
}

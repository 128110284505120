import { queryParent } from './tools'

const SELECTOR = '[data-auto-submit]'

class AutoSubmit {
  constructor(dom) {
    this.dom = dom
    this.form = queryParent('form')(this.dom)

    this.bindEvents()
  }

  bindEvents() {
    this.dom.addEventListener('change', () => {
      this.form.submit()
    })
  }
}

export const initAutoSubmit = () => {
  document.querySelectorAll(SELECTOR).forEach((dom) => {
    new AutoSubmit(dom)
  })
}

import * as Sentry from "@sentry/browser";

class FbPixelEvent {
  constructor(fbq, currency='USD') {
    this.fbq = fbq
    this.currency = currency
    if(!this.fbq) {
      Sentry.captureException('FB pixel not found');
    }
  }

  // FB
  // normal
  // 1. ViewContent           - postEvent
  // 2. AddToCart             - postEvent
  // 3. AddToWishlist         - X
  // 4. CompleteRegistration  - X
  // 5. InitiateCheckout      - X
  // 6. Purchase

  postEvent(event, postData) {
    try{
      if(postData) {
        this.fbq('track', event)
      }
    } catch(err) {
      Sentry.captureException(err);
    }
  }

  purchase(orderData) {
    if(orderData) {
      this.fbq('track', 'Purchase', {
        value: orderData.order_price,
        currency: orderData.currency,
      })
    }
  }
}
export default FbPixelEvent;
 
// queryParent(selector)(dom)
const queryParent = (selector) => (dom) => {
  if (!dom) {
    return
  }

  let parent = dom.parentNode

  while (parent) {
    if (parent.matches(selector)) {
      break
    }
    parent = parent.parentNode
  }

  return parent
}

// throttle(period)(callback)
// callback: (e) => { perform_what }
const throttle = (period) => {
  let lastTime = 0
  return (callback) => (e) => {
    const now = Date.now()
    if (now - lastTime >= period) {
      lastTime = now
      callback(e)
    }
  }
}
const getSiblings = (elem) => {
  return [...elem.parentElement.children].filter(children => children != elem)
}

const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}
export { queryParent, throttle, getSiblings, isIOS }

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['localeSelect', 'currencySelect']
  currencyMappingValue = {
    'en': 'USD',
    'zh-TW': 'TWD',
    'jp': 'JPY',
    'default': 'USD'
  }

  connect() {
    this.localeSelectTarget.addEventListener('change', this.changeLocale.bind(this))
  }

  submit = () => {
    window.location = '?locale=' + this.localeSelectTarget.value + '&currency=' + this.currencySelectTarget.value
  }

  changeLocale() {
    this.currencySelectTarget.value = this.currencyMapping(this.localeSelectTarget.value)
  }

  currencyMapping(locale) {
    return this.currencyMappingValue[locale] || this.currencyMappingValue['default']
  }
}

import { Controller } from "stimulus";
// deprecated
export default class extends Controller {
  static targets = ["fileImg", "msg", "category"];

  check(e) {
    if (!this._fileExistInAllDistinctDropzone() || !this._categoryChecked()) {
      alert(this.msgTarget.innerHTML);
      e.preventDefault();
      return false;
    }
    return true;
  }

  _categoryChecked() {
    let res = false;
    this.categoryTargets.forEach((tag) => {
      if (tag.checked) {
        res = true;
      }
    });
    return res;
  }

  _fileExistInAllDistinctDropzone() {
    console.log("in~~~~~~~~~~~~~~~~~");
    const fileImageElementsGroupByName = Object.values(
      this._groupBy(this.fileImgTargets, "name")
    );
    const isEmptyContent = (element) => element.innerHTML === "";

    return !fileImageElementsGroupByName.some((elementsWithInGroup) => {
      return elementsWithInGroup.every(isEmptyContent);
    });
  }

  _groupBy(arr, criteria) {
    console.log('in2~~~~~~~~~~~~~~~~~')
    const newObj = arr.reduce(function (acc, currentValue) {
      if (!acc[currentValue[criteria]]) {
        acc[currentValue[criteria]] = [];
      }
      acc[currentValue[criteria]].push(currentValue);
      return acc;
    }, {});
    return newObj;
  }
}

import { Controller } from "stimulus"


export default class extends Controller {
  static values = {
    postId: Number
  }
  static targets = ['wordcount', 'content']

  initialize() {
    this.post_content = "<p>Please write down more information about your model to enhance the post punches! eg: model size, etc...</p>"
    this.variant_content = "<p>Please write down variant description if needed.</p>"
  }

  connect() {
    this.importTinyMce()
      .then(() => {
        tinymce.init({
          selector: 'div[data-controller="editor"] .post_content',
          menubar: false,
          plugins: 'link lists wordcount image formatselect',
          toolbar: 'bold italic formatselect | blocks | align bullist | link image',
          width: '100%',
          content_css: false,
          content_style: '* { color: white }',
          skin: false,
          statusbar: false,
          setup: this.initSetupFunctions,
          images_upload_handler: this.images_upload_handler
        })

        tinymce.init({
          selector: 'div[data-controller="editor"] .variant_content[disabled="disabled"]',
          menubar: false,
          plugins: 'link lists wordcount',
          toolbar: 'bold italic | bullist numlist | link',
          width: '100%',
          height: 200,
          content_css: false,
          content_style: '* { color: #575D66; cursor: not-allowed }',
          skin: false,
          statusbar: false,
          setup: this.initDisabled,
        })

        tinymce.init({
          selector: 'div[data-controller="editor"] .variant_content',
          menubar: false,
          plugins: 'link lists wordcount',
          toolbar: 'bold italic | bullist numlist | link',
          width: '100%',
          height: 200,
          content_css: false,
          content_style: '* { color: white }',
          skin: false,
          statusbar: false,
          setup: this.initTextareaWordCount,
        })
      })
  }

  async importTinyMce() {
    await import("tinymce/tinymce")
    await import("tinymce/icons/default")
    await import("tinymce/themes/silver/theme")
    await import("tinymce/models/dom")
    await import("tinymce/plugins/link")
    await import("tinymce/plugins/lists")
    await import("tinymce/plugins/wordcount")
    await import("tinymce/plugins/image")
    await import("tinymce/skins/ui/oxide-dark/skin.min.css")
  }
  
  defaultContent (instance) {
    if(tinymce.activeEditor.getContent() == '') {
      tinymce.activeEditor.setContent("<p>Model Size:</p><p>XXX-1 40 x 30 x 30 mm</p><p>XXX-2 40 x 30 x 30 mm</p><p>12 model in total</p>")
    }
  }

  defaultVariantContent (instance) {
    if(tinymce.activeEditor.getContent() == '') {
      tinymce.activeEditor.setContent("<p>ctb or stl description</p>")
    }
  }

  initSetupFunctions = (editor) => {
    this.initWordCount(editor)
    this.initTextareaWordCount(editor)
  }

  initTextareaWordCount = (editor) => {
    editor.on('init', (event) => {
      editor.targetElm.value = tinymce.activeEditor.getContent({format : 'text'})

      var inputEvent = new Event('input');
      editor.targetElm.dispatchEvent(inputEvent);
    })
    editor.on('keyup', (event) => {
      editor.targetElm.value = tinymce.activeEditor.getContent({format : 'text'})

      var inputEvent = new Event('input');
      editor.targetElm.dispatchEvent(inputEvent);
    });
    editor.on('dragstart dragend dragover dragenter dragleave drop', function (event) {
      event.preventDefault();
      event.stopPropagation();
    });
  }

  initWordCount = (editor) => {
    var max = 2500;
    const submitElement = document.querySelector('button[type="submit"]');
    const exceedErrorMsgElement = document.getElementById('models-content-exceed-error');
    const blankErrorMsgElement = document.getElementById('models-content-blank-error');

    editor.on('init', (event) => {
      const numChars = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount();

      this.wordcountTarget.innerHTML = `${numChars} / ${max}`;
      exceedErrorMsgElement.classList.toggle('hidden', numChars < max);
    })
    editor.on('keyup', (event) => {
      const numChars = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount();
      const content = tinymce.get(editor.id).getContent({format: 'text'}).replace(/\s/g, '')

      this.wordcountTarget.innerHTML = `${numChars} / ${max}`;
      if(numChars <= max){
        exceedErrorMsgElement.classList.add('hidden')
      }
      blankErrorMsgElement.classList.toggle('hidden', content.length != 0);
    });
    editor.on('submit', (event) => {
      const numChars = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount();
      const content = tinymce.get(editor.id).getContent({format: 'text'}).replace(/\s/g, '')

      if(numChars > max) {
        event.preventDefault();
        exceedErrorMsgElement.classList.remove('hidden')
        const modelInfoElement = document.querySelector('.model-infos');
        if(!exceedErrorMsgElement.classList.contains('hidden')){
          modelInfoElement.scrollIntoView({ behavior: 'smooth' });
        }
        // hack prevent submit always disabled by ujs
        setTimeout(() => {
          submitElement.disabled = false;
        }, 1000);
      }
      if(content.length == 0){
        event.preventDefault();
        blankErrorMsgElement.classList.remove('hidden')
        const modelInfoElement = document.querySelector('.model-infos');
        if(!blankErrorMsgElement.classList.contains('hidden')){
          modelInfoElement.scrollIntoView({ behavior: 'smooth' });
        }
        // hack prevent submit always disabled by ujs
        setTimeout(() => {
          submitElement.disabled = false;
        }, 1000);
      }
    });
  }

  initDisabled = (editor) => {
    editor.on('init', (event) => {
      if (editor.targetElm.disabled) {
        editor.getBody().setAttribute('contenteditable', false);
      }
    })
  }

  initPlaceholder = (editor) => {
    let defaultContent

    if (editor.id == 'post_content') {
      defaultContent = this.post_content
    } else if (editor.id.startsWith('post_variants')) {
      defaultContent = this.variant_content
    }

    editor.on('init', function (e) {
      const content = editor.getContent();
      if (!content.trim()) {
        editor.setContent(defaultContent);
      }
    });

    editor.on('focus', function (e) {
      const content = editor.getContent().replace(/(\r\n|\n|\r)/gm, "");
      if (content === defaultContent) {
        editor.setContent('');
      }
    });

    editor.on('blur', function (e) {
      const content = editor.getContent();
      if (!content.trim()) {
        editor.setContent(defaultContent);
      }
    });
  }

  images_upload_handler = (blobInfo, progress) => new Promise((resolve, reject) => {
    const fileSize = blobInfo.blob().size
    const filename = blobInfo.filename()

    fetch(`/post_content_imgs/editor_upload?filename=${filename}&file_size=${fileSize}&post_id=${this.postIdValue}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .then(response => response.json())
      .then(res => {
        return fetch(res.presigned_url, {
          method: 'PUT',
          headers: {
            'Content-Type': blobInfo.blob().type, // 设置正确的文件 MIME 类型
          },
          body: blobInfo.blob(),
        });
      })
      .then(response => {
        if (response.ok) {
          resolve(response.url.split('?')[0])
        } else {
          reject('Upload failed')
        }
      })  
      .catch(error => {
        console.error('GetUploadLink error:', error);
        reject('Upload failed')
      });
  })

  disconnect () {
    tinymce.remove()
  }
}

const initFloatValidate = () => {
  const SELECTOR = 'input[data-input-float-validate]'
  document.querySelectorAll(SELECTOR).forEach((dom) => {
    // 初始化時，自動補齊兩位小數
    dom.value = parseFloat(dom.value).toFixed(2);
    
    // 輸入小數點不能超過小數點後兩位
    dom.addEventListener("input", (event) => {
      // 使用正則表達式來找到不符合規則的字符
      let regex = /^\d*\.?\d{0,2}$/;
      let newValue = event.target.value;

      if (!regex.test(newValue)) {
        // 如果不符合規則，則恢復到前一個值
        event.target.value = newValue.substring(0, newValue.length - 1);
      }
    })

    // 輸入不到兩位小數時，自動補齊兩位小數
    dom.addEventListener("blur", (event) => {
      dom.value = parseFloat(dom.value).toFixed(2);
    })
  })
}

export const initFormValidate = () => {
  initFloatValidate()
}

import * as Sentry from "@sentry/browser";

class AmplitudeEvent {
  constructor(amplitude, currency='USD') {
    this.amplitude = amplitude
    this.currency = currency
    if(!this.amplitude) {
      Sentry.captureException('Amplitude not found');
    }
  }

  viewPost(postData){
    if (postData) {
      this.amplitude.logEvent('View Post', {
        'post_id': postData.id,
        'post_title': postData.title,
        'post_category': postData.category,
      })
    }
  }

  addToCart(varnaitData){
    if (varnaitData) {
      this.amplitude.logEvent('Add To Cart', {
        'creator_id': varnaitData.post.creator.id,
        'creator_name': varnaitData.post.creator.username,
        'post_id': varnaitData.post.id,
        'post_title': varnaitData.post.title,
        'variant_id': varnaitData.id,
        'variant_title': varnaitData.name,
        'variant_price': varnaitData.price,
        'variant_type':  varnaitData.file_type,
        'phrozen_verified': varnaitData.phrozen_verified,
      })
    }
  }

  purchase(orderData){
    if (orderData) {
      this.amplitude.logEvent('Purchase Completed', {
        'order_id': orderData.order_id,
        'order_transaction_numer': orderData.transaction_id,
        'order_price': orderData.order_price,
        'order_discount': orderData.order_discount,
        'order_paid_at': orderData.order_paid_at,
        'item_details': orderData.items
      })
    }
  }

  userSignup(userData){
    if (userData) {
      this.amplitude.logEvent('Signed Up', {
        'user_type': userData.user_type,
        'created_data': userData.created_at,
        'signup_method': userData.signup_method
      })
    }
  }

  userLogin(userData){
    if (userData) {
      this.amplitude.setUserId(userData.user_id);
      this.amplitude.logEvent('Logged In', {
        'last_logged_in_date': userData.last_sign_in_at,
        'login_method': userData.login_method
      })
    }
  }

  userLogout(){
    // this.amplitude.logEvent('test', { 'event_name': 'logout test' })
    this.amplitude.setUserId(null);
  }
}

export default AmplitudeEvent;
 
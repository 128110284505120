import { Controller } from "stimulus"
import i18next from 'i18next'

export default class extends Controller {
  static targets = ['form']

  connect() {}

  submit = () => {
    // 创建 FormData 对象
    var formData = new FormData(this.formTarget);
  
    const requestUrl = this.formTarget.action
    const csrfToken = formData.get('authenticity_token')

    fetch(requestUrl, {
      method: 'post',
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData
    })
    .then((res) => {
      if (res.ok) {
        alert(i18next.t('report_model.success'))
      } else {
        alert(i18next.t('report_model.duplicate'))
      }
      // close modal
      const event = new CustomEvent("modalClose", {});
      window.dispatchEvent(event);
    })
  }
}

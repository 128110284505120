import $ from 'jquery'

export const initErrorInputKlass = (dom) => {
  $(dom).find(".error .default-input-klass").each(function(e){
    const parentEl = this.closest('.error')

    $(this).on('focus', function(){
      if (parentEl) {
        parentEl.classList.remove('error')
      }
      parentEl.querySelectorAll('.error-msg').forEach((errMsg) => {
        errMsg.remove()
      })
    })
  })
}
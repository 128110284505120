import $ from 'jquery'

export const initSearchbarBtn = (dom) => {
  $(dom).find("form[data-searchbar]").each(function(e){
    const input = $(this).find('input.default-input-klass')[0]
    const cancelBtn = $(this).find('.cancel-btn')[0]
    const submitBtn = $(this).find("input[type='submit']")[0]
    const isSearched = $(cancelBtn).is(':visible')

    $(input).on('keyup', function(e){
      const inputVal = $(this).val()

      if( inputVal != '' ){
        $(submitBtn).attr('disabled', false)
      } else {
        $(submitBtn).attr('disabled', true)
      }
    })

    if( isSearched ){
      $(input).on('focus', function(e){
        $(submitBtn).attr('hidden', false)
        $(cancelBtn).attr('hidden', true)
      })
    }
  })
}
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];
  static classes = ['hidden']

  showModel(e) {
    e.preventDefault()
    this.modalTarget.classList.remove(this.hiddenClass)
  }

  closeModal(e) {
    e.preventDefault()
    this.modalTarget.classList.add(this.hiddenClass)
  }
}

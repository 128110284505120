import { Controller } from "stimulus"

export default class extends Controller {
  // Post row comment controller
  source_id = null

  connect() {
    this.element[this.identifier] = this
  }

  submitSurvey(e) {
    e.preventDefault()
    const sourceId = e.target.dataset.sourceId
    const title = e.target.dataset.title
    const category = e.target.dataset.category
    // ask-options
    const selectedOptions = document.querySelectorAll('.report-options input[type=radio]:checked')
    if(selectedOptions.length == 1) {
      const requestUrl = `/surveys`
      // post need csrf token
      fetch(requestUrl, {
        method: 'post',
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          source_id: sourceId,
          title,
          category,
          content: { text: selectedOptions[0].value },
        }),
      })
      .then((res) => {
        if (res.status === 200) {
          alert('Submit success!')  
        } else if (res.status === 401) {
          window.location.href = '/users/sign_up'
        } else {
          alert('Duplicate Submit')
        }
        this.element.modal.closeModal()
      })
      .catch((error) => {
        alert('Submit Failed')
        this.element.modal.closeModal()
      })
    } else {
      alert('Please select one option')
    }
  }
}


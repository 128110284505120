import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['filterBtn', 'closeBtn', 'wrapArea', 'maskArea']
  connect() {}

  filterBtnClicked(e) {
    e.preventDefault()
    e.stopPropagation()
    this.toggleDisplay()
  }

  hiddenDisplay() {
    this.maskAreaTarget.classList.add('hidden')
  }

  toggleDisplay() {
    this.maskAreaTarget.classList.toggle('hidden')
  }

}


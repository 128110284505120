export const initHeaderSearch = (formId) => {
  const form = document.getElementById(formId)
  if (!form) {
    return
  }

  form.querySelectorAll('.search-type').forEach((rad)=>{
    rad.addEventListener('change', (e)=>{
      const rbt = e.target
      const action = rbt.dataset.formAction
      form.action = action
    })
  })

}

import $ from 'jquery'

export const initCategorySelect = (dom) => {
  $(dom).find(".category_lv1").each(function(){
    $(this).change(function() {
      const cateLv1 = $(this).val()
      const lv2Items = $(dom).find(".category_lv2")
      const lv3Items = $(dom).find(".category_lv3")
      lv2Items.addClass('hidden')
      lv3Items.addClass('hidden')

      $(dom).find("[data-parent-id='" + cateLv1 + "'] + .category_lv2").removeClass('hidden')

      setSelectedCategory()
    });
  })

  $(dom).find(".category_lv2").each(function(){
    $(this).change(function() {
      const cateLv2 = $(this).val()
      const lv3Items = $(dom).find(".category_lv3")
      lv3Items.addClass('hidden')

      $(dom).find("[data-parent-id='" + cateLv2 + "'] + .category_lv3").removeClass('hidden')

      setSelectedCategory()
    });
  })

  $(dom).find(".category_lv3").each(function(){
    $(this).change(function() {
      setSelectedCategory()
    });
  })

  function setSelectedCategory() {
    const selectedCategory = $("select + .category-select:not(.hidden)").last().find('.ts-input .item')[0].dataset['value']
    $('#post_category_id').val(selectedCategory)
  }
}
const SELECTOR = '[data-slide]'
const SLIDE_CONTAINER = '[data-slide-container]'
const SLIDE_ITEM = '[data-slide-item]'
const ACTION_PREV = '[data-slide-action="prev"]'
const ACTION_NEXT = '[data-slide-action="next"]'
const SLIDE_SECOND = '[data-slide-second]'

const DATA_SLIDE_NUMBER = 'slide'
const DATA_SLIDE_DURATION = 'duration'
const DEFAULT_SLIDE_DURATION = 300

class Slide {
  constructor($dom) {
    this.$dom = $dom
    this.$container = this.$dom.querySelector(SLIDE_CONTAINER)
    this.$prev = this.$dom.querySelector(ACTION_PREV)
    this.$next = this.$dom.querySelector(ACTION_NEXT)

    // data
    this.slideDuration = Number(this.$dom.dataset[DATA_SLIDE_DURATION]) || DEFAULT_SLIDE_DURATION
    this.slide_items = this._getSlideItems()
    this.total = this.slide_items.length
    this.slideItemWidth = this._getSlideItemWidth()
    this.slideNumber = Number(this.$dom.dataset[DATA_SLIDE_NUMBER])
    this.viewNumber = this._getViewNumber()

    // init
    this.setTransform()
    this.setPosition(0)
    this.bindEvents()
  }

  setTransform() {
    this.$container.style.transition = `left ${this.slideDuration / 1000}s`
  }

  slide(newPosition) {
    this.setPosition(newPosition)
  }

  setPosition(newPosition) {
    this.position = newPosition
    this.updatePositionView()
    this.updateActionView()
  }

  updatePositionView() {
    this.$container.style.left = this.position * this.slideItemWidth * -1 + 'px'
  }

  updateActionView() {
    this.$prev.classList.remove('hidden')
    this.$next.classList.remove('hidden')

    if (this.position === 0) {
      this.$prev.classList.add('hidden')
    }
    if (this.position + this.viewNumber >= this.total) {
      this.$next.classList.add('hidden')
    }
  }

  bindEvents() {
    this.$prev.addEventListener('click', () => {
      this.slide(this._getPrevPosition())
    })

    this.$next.addEventListener('click', () => {
      this.slide(this._getNextPosition())
    })

    window.addEventListener('resize', () => {
      this.slideItemWidth = this._getSlideItemWidth()
      this.viewNumber = this._getViewNumber()
    })

    if(document.querySelector(SLIDE_SECOND) && window.innerWidth < 768){
      this.slide(this._getNextPosition())
    }

  }

  // private
  _getSlideItems() {
    const $slideItemArray = [...this.$container.querySelectorAll(SLIDE_ITEM)]

    return $slideItemArray.filter(a => a.offsetParent)
  }

  _getSlideItemWidth() {
    const $slideItem = this.slide_items[0]

    if($slideItem){
      return parseFloat(window.getComputedStyle($slideItem).getPropertyValue('width'))
    }else{
      return 0.0
    }
  }

  _getViewNumber() {
    const paddingLeftWidth = parseFloat(window.getComputedStyle(this.$dom).getPropertyValue('padding-left'))
    const paddingRightWidth = parseFloat(window.getComputedStyle(this.$dom).getPropertyValue('padding-right'))
    return (this.$dom.offsetWidth - paddingLeftWidth - paddingRightWidth) / this.slideItemWidth
  }

  _getPrevPosition() {
    const newPosition = this.position - this.slideNumber
    return newPosition < 0 ? 0 : newPosition
  }

  _getNextPosition() {
    const newPosition = this.position + this.slideNumber
    if (newPosition + this.viewNumber >= this.total) {
      return this.total - this.viewNumber
    } else {
      return newPosition
    }
  }
}

export const initSlide = () => {
  document.querySelectorAll(SELECTOR).forEach(($dom) => new Slide($dom));
}

import $ from 'jquery'

export const initSelect2FileGroup = (dom) => {
  $(dom).find("[data-select2-model]").each(function(){
    const modelName = $(this).data('select2-model')
    let params = {
      "width": $(this).data("width") || "100%",
      "tokenSeparators": [",", " "],
    }

    params["ajax"] = {
      url: function (params) {
        const path = window.location.pathname.match(/\/profile\/models\/\d+/)[0]
        return path + '/file_groups';
      },
      dataType: 'json',
      delay: 250,
      processResults: function (data, params) {
        return {results: data}
      },
      cache: true
    }
    $(this).select2(params)
  })
}
import * as Sentry from "@sentry/browser";

class GtmEvent {
  constructor(dataLayer, currency='USD') {
    this.dataLayer = dataLayer
    this.currency = currency
    if(!this.dataLayer) {
      Sentry.captureException('GTM not found');
    }
  }

  cartEventInfo(cartData) {
    return {
      currency: this.currency,
      value: cartData.finalPrice,
      items: cartData.items.map((item, index) => ({
          item_id: item.id,
          item_name: item.title,
        })
      )
    }
  }

  postEventInfo(postData) {
    return {
      currency: this.currency,
      value: postData.price,
      items: [
        {
          item_id: postData.id,
          item_name: postData.title,
          currency: this.currency,
          index: 0,
          item_brand: postData.creator,
          price: postData.price,
          quantity: 1
        }
      ]
    }
  }

  promotionEventInfo(promotionData, cartData) {
    if(promotionData) {
      promotionData = {
        promotion_id: promotionData.id,
        promotion_name: promotionData.code,
      }
    } else {
      promotionData = {}
    }
    return {
      ...promotionData,
      items: cartData.items.map((item, index) => ({
        item_id: item.id,
        item_name: item.title,
      }))
    }
  }

  downloadEventInfo(downloadData) {
    if(downloadData) {
      downloadData = {
        post_id: downloadData.id,
        post_title: downloadData.title,
      }
    } else {
      downloadData = {}
    }
    return {
      ...downloadData,
    }
  }

  userEventInfo(userData) {
    if(userData) {
      userData = {
        utm_source: userData.utm_source,
      }
    } else {
      userData = {}
    }
    return {
      ...userData,
    }
  }

  // ga4 ecommerce event list

  // normal
  // 1. view_item         - postEventInfo 
  // 2. add_to_cart       - postEventInfo
  // 3. remove_from_cart  - postEventInfo
  // 4. add_to_wishlist   - postEventInfo
  
  // checkout start
  // 5. view_cart         - cartEventInfo
  // 6. begin_checkout    
  // 7. add_shipping_info
  // 8. add_payment_info
  // 9. purchase          - puchaseInfo

  // 10. refund
  // 11. view_promotion   - promotionEvent
  // 12. select_promotion - promotionEvent 
  // 13. view_item_list
  // 14. select_item      

  postEvent(event, postData) {
    try{
      if(postData) {
        this.dataLayer.push({ ecommerce: null });
        this.dataLayer.push({
          event,
          ecommerce: {
            ...this.postEventInfo(postData),
          }
        })
      }
    } catch(err) {
      Sentry.captureException(err);
    }
  }

  cartEvent(event, cartData) {
    try{
      if(cartData) {
        this.dataLayer.push({ ecommerce: null });
        this.dataLayer.push({
          event,
          ecommerce: {
            ...this.cartEventInfo(cartData),
          }
        })
      }
    } catch(err) {
      Sentry.captureException(err);
    }
  }

  trackDownload(event, downloadData) {
    try{
      if(downloadData) {
        this.dataLayer.push({ params: null });
        this.dataLayer.push({
          event,
          params: {
            ...this.downloadEventInfo(downloadData),
          }
        })
      }
    } catch(err) {
      Sentry.captureException(err);
    }
  }

  userEvent(event, userData) {
    try{
      if(cartData) {
        this.dataLayer.push({ params: null });
        this.dataLayer.push({
          event,
          params: {
            ...this.userEventInfo(userData),
          }
        })
      }
    } catch(err) {
      Sentry.captureException(err);
    }
  }

  promotionEvent(event, promotionData, cartData) {
    try{
      if(cartData) {
        this.dataLayer.push({ ecommerce: null });
        this.dataLayer.push({
          event,
          ecommerce: {
            ...this.postEventInfo(promotionData, cartData),
          }
        })
      }
    } catch(err) {
      Sentry.captureException(err);
    }
  }

  share(shareData) {
    try{
      if(shareData) {
        this.dataLayer.push({ params: null });
        this.dataLayer.push({
          event: 'share',
          params: {
            method: shareData.method,
            content_type: shareData.content_type,
            item_id: shareData.item_id,
          }
        })
      }
    } catch(err) {
      Sentry.captureException(err);
    }
  }

  purchase(orderData) {
    if(orderData) {
      this.dataLayer.push({ ecommerce: null });
      this.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: orderData.transaction_id,
          value: orderData.order_price,
          tax: orderData.tax,
          shipping: orderData.shipping,
          currency: orderData.currency,
          items: orderData.items.map((item, index) => ({
            item_id: item.post_id,
            item_name: item.post_title,
            item_brand: item.creator_name,
            item_category: item.post_category,
            price: item.variant_price,
            quantity: item.quantity
          }))
        }
      })
    }
  }
}
export default GtmEvent;
 
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['result']
  afterSuccessRequest(e) {
      let [data, status, xhr] = e.detail
      this.replaceResult(xhr.response)
  }

  replaceResult(content) {
      this.resultTarget.innerText = ''
      this.resultTarget.insertAdjacentHTML('afterbegin', content)
  }

  backBtnClicked(e){
      e.stopPropagation()
      let maskArea = document.getElementById('mask-area')
      if (maskArea) {
        maskArea.classList.remove('hidden')
      }
      document.getElementById('modal').remove();
  }
}

class FooterAnimation {
  constructor(footer, showPx, hidePx, delay) {
    this.footer = footer;
    this.showPx = showPx;
    this.hidePx = hidePx;
    this.delay = delay;
    this.lastScrollTop = 0;
    this.timer = null;
    this.isScrollingDown = false;

    this.init(footer);
  }

  init(footer) {
    this.bindEvents();
  }

  bindEvents() {
    const scrollHandler = this.createScrollHandler();
    const debouncedScrollHandler = this.debounce(scrollHandler, 300);

    if (window.isFirefox()) {
      window.addEventListener('scroll', debouncedScrollHandler, true);
    } else {
      window.addEventListener('scroll', debouncedScrollHandler);
    }
  }

  createScrollHandler() {
    return () => {
      clearTimeout(this.timer);
      const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;

      // 檢查滾動方向
      this.isScrollingDown = scrollTop > this.lastScrollTop;
      this.lastScrollTop = Math.max(0, scrollTop); // 防止滾動過程中負值

      // 設置一個新的計時器來檢查滾動停止後的一段時間
      this.timer = setTimeout(() => this.checkScroll(), this.delay);
    };
  }

  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  checkScroll() {
    const scrollTop =  document.body.scrollTop || window.scrollY || document.documentElement.scrollTop;

    if (this.isScrollingDown) {
      // 向下滾動
      if (scrollTop > this.showPx) {
        this.showFooter();
      }
    } else {
      // 向上滾動
      if (scrollTop < this.hidePx) {
        this.hideFooter();
      }
    }
  }

  showFooter() {
    if (!this.footer.classList.contains('visible-footer')) {
      this.footer.classList.remove('hidden-footer');
      this.footer.classList.add('visible-footer');
    }
  }

  hideFooter() {
    if (this.footer.classList.contains('visible-footer')) {
      this.footer.classList.add('hidden-footer');
      this.footer.classList.remove('visible-footer');
    }
  }
}

export const initFooterAnimation = (selector, showPx, hidePx, delay) => {
  const footer = document.querySelector(selector);
  if (footer) {
    new FooterAnimation(footer, showPx, hidePx, delay);
  }
}

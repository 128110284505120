import i18next from "i18next"

export const contentEllipsis = () => {
  const ellipsisTarget = document.querySelectorAll('[data-content-ellipsis]')
  const moreContent = i18next.t('posts.show.see_more');
  const lessContent = i18next.t('posts.show.see_less');

  // reset opened btn close
  const ellipsisBtns = document.querySelectorAll(".ellipsis-btn[data-ellipsis='false']")

  for(const ellipsisBtn of ellipsisBtns) {
    ellipsisBtn.textContent = moreContent;
    ellipsisBtn.dataset.ellipsis = 'true';
  }

  for(const ellipsisEl of ellipsisTarget) {
    ellipsisEl.classList.add('line-clamp')

    const lineClamp = ellipsisEl.dataset.contentEllipsis

    ellipsisEl.style.webkitLineClamp = lineClamp

    if (ellipsisEl.offsetHeight < ellipsisEl.scrollHeight || ellipsisEl.offsetWidth < ellipsisEl.scrollWidth) {
      const ellipsisBtn = document.createElement('div')
      ellipsisBtn.classList = "ellipsis-btn underline cursor-pointer"

      const nextEl = ellipsisEl.nextSibling
      const height = 1.5 * lineClamp

      if ( nextEl.classList == undefined || !nextEl.classList.contains('ellipsis-btn') ){
        ellipsisEl.parentNode.insertBefore(ellipsisBtn, ellipsisEl.nextSibling);
      }

      ellipsisEl.style.height = `${height}rem`

      ellipsisBtn.textContent = moreContent;
      ellipsisBtn.dataset.ellipsis = 'true';

      ellipsisBtn.addEventListener('click', (e) => {
        if ( ellipsisBtn.dataset.ellipsis == 'true' ) {
          ellipsisBtn.textContent = lessContent;
          ellipsisBtn.dataset.ellipsis = 'false';
          ellipsisEl.style.webkitLineClamp = null
          ellipsisEl.style.height = 'auto'
        } else {
          ellipsisBtn.textContent = moreContent;
          ellipsisBtn.dataset.ellipsis = 'true';
          ellipsisEl.style.webkitLineClamp = ellipsisEl.dataset.contentEllipsis
          ellipsisEl.style.height = `${height}rem`
        }
      })
    }
  }
}
